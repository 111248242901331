import React, { type HTMLAttributes } from 'react'
import styled from '@emotion/styled'
import fontSizes from 'design-system/tokens/fontSizes'
import fontWeights from 'design-system/tokens/fontWeights'
import spacing from 'design-system/tokens/spacing'
import colors from 'design-system/tokens/colors'
import { useModalProps } from './ModalPropsProvider'

const StyledHeader = styled.header`
  border-radius: 3px 3px 0 0;
  box-sizing: content-box;
  flex: none;
  font-size: ${fontSizes.h4};
  font-weight: ${fontWeights.semibold};
  line-height: 1em;
  margin: ${spacing.m1} 0;
  min-height: 1em;
  padding: 0;
  padding-right: ${spacing.xxxl};
  position: relative;
  color: ${colors.black};
`

const Header = (props: HTMLAttributes<HTMLHeadingElement>) => {
  const modalProps = useModalProps()
  return <StyledHeader id={`modal_title_${modalProps?.id}`} {...props} />
}

export default Header
